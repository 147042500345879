<div class="container-authenticated bottom-no-padding" [ngClass]="{'container-authenticated-ios': isIos}">
    <h1 class="inline-header">Find an Artist</h1>
    <div class="right-group float-right">
        <button mat-button color="light" class="icon-button" (click)="openFilterDialog()">
            <mat-icon>search</mat-icon>
            <ng-container *ngIf="filters.length > 0; else emptyFilter">
                <ng-container *ngFor="let filter of filters; let i = index"> {{ i !== filters.length - 1 ? filter + ', '
                    : filter}}</ng-container>
            </ng-container>
            <ng-template #emptyFilter> Search</ng-template>
        </button>
        <button *ngIf="filters.length > 0" mat-icon-button color="light" matTooltip="Clear Filters"
            (click)="clearFilters()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <br class="clear" />
    <mat-divider></mat-divider>
    <br />
    <!-- <cdk-virtual-scroll-viewport #scroller appendOnly autoSize class="virtual-scroll-viewport" -->
    <cdk-virtual-scroll-viewport #scroller appendOnly itemSize="100px" class="virtual-scroll-viewport"
        [ngClass]="{'hidden':!artists || artists.length < 1}">
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start"
            class="row-layout">
            <div class="item" *cdkVirtualFor="let artist of artists" fxFlex.lt-sm="0 1 100%" fxFlex.lt-md="0 1 50%"
                fxFlex.lt-lg="0 1 33.33%" fxFlex.lt-xl="0 1 25%" fxFlex="0 1 20%">
                <mat-card class="mat-elevation-z6">
                    <div class="view-artist" routerLink="{{ '/artist/' + artist.id }}">
                        <mat-card-header class="event-card-header">
                            <mat-card-title>{{ artist.displayName }}</mat-card-title>
                            <mat-card-subtitle>{{ artist.tags }}</mat-card-subtitle>
                            <span class="card-title-right">{{ artist.pricePerHour | currency }}/hr<br />{{
                                artist.distance |
                                number:'1.0-0' }} miles</span>
                        </mat-card-header>
                        <img mat-card-image src="{{ imageBaseUrl + artist.profileImageUrl }}" alt="Band Photo">
                        <mat-card-content class="event-card-content">
                            <p>
                                {{ artist.bio }}
                            </p>
                        </mat-card-content>
                    </div>
                    <mat-card-actions>
                        <button class="icon-button" mat-button (click)="scheduleEvent(artist.id)">
                            <mat-icon>today</mat-icon> SCHEDULE EVENT
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>

    </cdk-virtual-scroll-viewport>
    <div [ngClass]="{'hidden': artists && artists.length > 0}">
        <div class="no-artists">
            <span class="center-align">
                <mat-icon mat-list-icon class="empty-artist-list">screen_search_desktop</mat-icon>
                <h2>Sorry there were no results for your search. Please try other keywords and dates.</h2>
            </span>
        </div>
    </div>
</div>