<div class="statusBarIos" *ngIf="isIos"></div>
<ng-container *ngIf="theme">
<mat-toolbar class="navbar light_navbar mat-elevation-z6" [ngClass]="{'iosMargin' : isIos}">
    <span class="menu-spacer" [ngClass]="{'show': isNative}">
        <button mat-icon-button matTooltip="Back" class="navbar-back-button" [ngClass]="{ 'show' : routingState.history.length > 1}" (click)="back()">
            <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
    </span>
    <img class="logo logo-left" [ngClass]="{ 'hidden' : isNative }" [src]="desktopLogo" />
    <!-- <span class="logo-text" [ngClass]="{ 'hidden' : isNative }">Toon Balloon</span>     -->
    <span class="navbar-spacer">
        <img class="logo logo-center" [ngClass]="{ 'show' : isNative }"
            [src]="mobileLogo" />
    </span>
    <div *ngIf="!isNative" class="desktop-device">
        <ng-container *ngIf="authService.authenticated$ | async">
            <button mat-button routerLink="/edit-profile">
                {{ username && username !== '' ? username : 'My Profile' | lowercase }}
            </button>
        </ng-container>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="more" matTooltip="More" class="navbar-more-button">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #more class="matMenu">
        <button class="menuButtonToggle" mat-menu-item (click)="changeTheme()">
            <mat-icon>{{ theme === 'dark' ? 'light_mode' : 'dark_mode' }}</mat-icon>
            <span>{{ theme === 'dark' ? 'Light Mode' : 'Dark Mode' }}</span>
        </button>
        <ng-container *ngIf="authService.authenticated$ | async; else menuLoginTemplate">            
            <button [ngClass]="{'mobile-device':!isNative}" mat-menu-item
                routerLink="/edit-profile">
                <mat-icon>account_circle</mat-icon>
                <span>{{ username && username !== '' ? username : 'My Profile' | lowercase }}</span>
            </button>
            <button mat-menu-item (click)="changePassword()">
                <mat-icon>password</mat-icon>
                <span>Change Password</span>
            </button>
            <button mat-menu-item (click)="deleteAccountDialog()">
                <mat-icon>delete_forever</mat-icon>
                <span>Delete Account</span>
            </button>
            <ng-container *ngFor="let route of adminRouteLinks">
                <button *ngIf="!route.hide" mat-menu-item routerLink="{{ route.link }}" class="admin-menu" [ngClass]="{'show' : isNative }">
                    <mat-icon>{{ route.icon }}</mat-icon>
                    <span>{{ route.name }}</span>
                </button>                
            </ng-container>
            <button mat-menu-item (click)="signOut()">
                <mat-icon>logout</mat-icon>
                <span>Sign Out</span>
            </button>            
        </ng-container>
        <ng-template #menuLoginTemplate>
            <button mat-menu-item routerLink="/sign-in">
                <mat-icon>login</mat-icon>
                <span>Sign In</span>
            </button>
        </ng-template>
    </mat-menu>
</mat-toolbar>

<mat-toolbar *ngIf="isAuthenticated && (authService.signUpStatus$ | async) && location.path() !== '/register'"
    color="primary" class="bottom-navbar mat-elevation-z6"
    [ngClass]="{ 'hidden' : hideNavbar, 'bottom-navbar-native' : isNative }">

    <mat-button-toggle-group>
        <ng-container *ngIf="this.roles?.includes('Artist') && this.authService.authenticatedUserData$ | async as user">
            <ng-container *ngIf="this.stripeService.stripeAccountStatus$ | async as stripeStatus">
                <ng-container *ngIf="!stripeStatus.setup; else default">
                    <mat-button-toggle [stripeConnectButton]="{ stripeAccountId: user.stripeAccountId, linkType: 'account_onboarding' }">
                        <mat-icon matBadge="setup" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">payments</mat-icon>
                        <span>Payments</span>
                    </mat-button-toggle>
                </ng-container>
                <ng-template #default>
                    <mat-button-toggle [stripeConnectButton]="{ stripeAccountId: user.stripeAccountId, linkType: 'login' }">
                        <mat-icon matBadge="{{ !stripeStatus.charges ? 'attn' :  !stripeStatus.verify ? 'verify' : '' }}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="{{ !stripeStatus.charges ? 'warn' :  !stripeStatus.verify ? 'verify' : 'accent' }}">payments</mat-icon>
                        <span>Payments</span>
                    </mat-button-toggle>
                </ng-template>
            </ng-container>
        </ng-container>
        <mat-button-toggle routerLink="/edit-profile">
            <mat-icon>account_circle</mat-icon>
            <span>Profile</span>
        </mat-button-toggle>
        <mat-button-toggle routerLink="/bookings">
            <mat-icon>event</mat-icon>
            <span>Bookings</span>
        </mat-button-toggle>
        <mat-button-toggle *ngIf="roles?.includes('Artist')" routerLink="/shows">
            <mat-icon>music_note</mat-icon>
            <span>Shows</span>
        </mat-button-toggle>
        <mat-button-toggle routerLink="/browse">
            <mat-icon>apps</mat-icon>
            <span>Browse</span>
        </mat-button-toggle>
    </mat-button-toggle-group>

</mat-toolbar>
</ng-container>