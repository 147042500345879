<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="0px grid" fxLayoutAlign="flex-start">
    <div fxFlex="0 1 40%" fxFlex.lt-md="100%" fxHide="true" fxHide.gt-sm="false" class="sign-in-image" [ngStyle]="{ 'background': 'url('+ signInImage +') center center / cover no-repeat' }">        
    </div>
    <div fxFlex="0 1 60%" fxFlex.lt-md="100%">
        <div class="container">
            <div class="center-form">
                <form [formGroup]="signInForm">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div fxFlex="0 1 20%" fxFlex.lt-md="0 1 25%" fxFlex.lt-sm="100%">
                        </div>
                        <div fxFlex="0 1 60%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                            <img class="image-logo" [src]="signInLogo" />
                            <h1>Sign In</h1>
                            <mat-divider></mat-divider>
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="Members email." formControlName="email" type="email">
                                <mat-hint>The email for your account.</mat-hint>
                                <mat-error
                                    *ngIf="signInForm.controls['email'].invalid && (signInForm.controls['email'].dirty || signInForm.controls['email'].touched)">
                                    <ng-container *ngIf="signInForm.controls['email'].errors?.['required']">Email is
                                        required.
                                    </ng-container>
                                    <ng-container *ngIf="signInForm.controls['email'].errors?.['email']">Must be a valid
                                        email
                                        address.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Password</mat-label>
                                <input matInput placeholder="Enter your password." formControlName="password"
                                    [type]="hide ? 'password' : 'text'" (keyup.enter)="signInForm.valid && !signInSaving ? signIn() : null">
                                <button type="button" tabindex="-1" mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-hint>Minimum(6 characters, 1 special, 1 uppercase, 1 lowercase)</mat-hint>
                            </mat-form-field>
                            <br />
                            <br />
                            <div class="text-center">
                                <button class="icon-button" mat-raised-button color="primary" [disabled]="!signInForm.valid || signInSaving"
                                    (click)="signIn()"><mat-icon>login</mat-icon> Sign
                                    In</button>
                                <br />
                                <br />
                                <button class="icon-button" mat-button color="light" routerLink="/forgot-password"><mat-icon>password</mat-icon> Forgot Password</button>
                                <br />
                                <br />
                                <button class="icon-button" mat-button color="light" routerLink="/create-account"><mat-icon>person_add</mat-icon> Create New Account</button>
                            </div>
                        </div>
                        <div fxFlex="0 1 20%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>