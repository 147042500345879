<form [formGroup]="discountCodeForm">
    <h1 mat-dialog-title>{{ data.mode | titlecase }} Discount Code</h1>
    <div mat-dialog-content>
        <input type="hidden" formControlName="userId" />
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Code</mat-label>
            <input matInput placeholder="MUSIC50OFF" formControlName="code" type="text">
            <mat-hint>The code your users can use to get a discount.</mat-hint>
            <mat-error
                *ngIf="discountCodeForm.controls['code'].invalid && (discountCodeForm.controls['code'].dirty || discountCodeForm.controls['code'].touched)">
                <ng-container *ngIf="discountCodeForm.controls['code'].errors?.['required']">Code is
                    required.
                </ng-container>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Percent Off</mat-label>
            <input matInput placeholder="25" formControlName="percentOff" type="number" />
            <mat-hint>The discount percentage.</mat-hint>
            <mat-error
                *ngIf="discountCodeForm.controls['percentOff'].invalid && (discountCodeForm.controls['percentOff'].dirty || discountCodeForm.controls['percentOff'].touched)">
                <ng-container *ngIf="discountCodeForm.controls['percentOff'].errors?.['required']">Percent Off is
                    required.
                </ng-container>
                <ng-container *ngIf="discountCodeForm.controls['percentOff'].errors?.['min']">Must be a minimum of 1 percent off.
                </ng-container>
                <ng-container *ngIf="discountCodeForm.controls['percentOff'].errors?.['max']">Must be a maximum of 90 percent off.
                </ng-container>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Duration</mat-label>
            <mat-select formControlName="duration" (selectionChange)="checkDuration($event)">
                <mat-option value="once">
                    Once
                </mat-option>
                <mat-option value="repeating">
                    Repeating
                </mat-option>
                <mat-option value="forever">
                    Forever
                </mat-option>
            </mat-select>
            <mat-hint>How long can the code be used?</mat-hint>
            <mat-error
                *ngIf="discountCodeForm.controls['duration'].invalid && (discountCodeForm.controls['duration'].dirty || discountCodeForm.controls['duration'].touched)">
                <ng-container *ngIf="discountCodeForm.controls['duration'].errors?.['required']">Duration is
                    required.
                </ng-container>
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="durationLength" appearance="outline" color="accent">
            <mat-label>Duration Months</mat-label>
            <input matInput placeholder="3" formControlName="durationMonths" type="number">
            <mat-hint>The number of months the code is active.</mat-hint>
            <mat-error
                *ngIf="discountCodeForm.controls['durationMonths'].invalid && (discountCodeForm.controls['durationMonths'].dirty || discountCodeForm.controls['durationMonths'].touched)">
                <ng-container *ngIf="discountCodeForm.controls['durationMonths'].errors?.['required']">Duration Months
                    is
                    required.
                </ng-container>
                <ng-container *ngIf="discountCodeForm.controls['durationMonths'].errors?.['min']">Must be a minimum of 1 month duration.
                </ng-container>
            </mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button class="icon-button" mat-button (click)="onCancelClick()">
            <mat-icon>close</mat-icon> Cancel
        </button>
        <button class="icon-button" mat-button [disabled]="!discountCodeForm.valid || discountCodeSaving"
            (click)="saveDiscountCode()" cdkFocusInitial>
            <mat-icon>save_alt</mat-icon> Save
        </button>
    </div>
</form>