import { Component, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';
import { PlatformMediaService } from 'src/app/admin/services/platform-media/platform-media.service';
import { Subscription } from 'rxjs';

// environments
import { GlobalConstants } from 'src/app/global/constants/global';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnDestroy {

  imageBaseUrl: string; // the base url for the images
  signInImage: string; // object with the default sign in image
  signInLogo: string; // object with the default sign in logo
  signInSaving: boolean = false; // flag that disables save button while saving to prevent multiple clicks
  hide: boolean = true; // flag to tell input to show or hide password

  // creates the form group
  signInForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template
  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private platformMediaService: PlatformMediaService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.globalConstants = GlobalConstants;
    this.imageBaseUrl = environment.imageBaseUrl;

    let theme = localStorage.getItem('theme');

    this.subscriptions.add(
      this.platformMediaService.platformMediaDefault$.subscribe(defaults => {
        if (defaults && defaults && defaults.length > 0) {

          let image = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.signInImageType.id);
          let logo = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.mainLogoType.id);

          if (image && image.length > 0 && image[0].mediaUrl && this.imageBaseUrl) {
            this.signInImage = this.imageBaseUrl + image[0].mediaUrl;
          } else {
            this.signInImage = "assets/default-images/sign-in.webp";
          }

          if (logo && logo.length > 0 && logo[0].mediaUrl && this.imageBaseUrl) {
            this.signInLogo = this.imageBaseUrl + logo[0].mediaUrl;
          } else {
            if (theme === 'light') {
              this.signInLogo = "assets/logos/toonballoon_logo_dark.webp";
            } else {
              this.signInLogo = "assets/logos/toonballoon_logo_light.webp";
            }
          }
        } else {
          this.signInImage = "assets/default-images/sign-in.webp";
          // this.signInLogo = "assets/logos/toon-balloon-logo.webp";
          if (theme === 'light') {
            this.signInLogo = "assets/logos/toonballoon_logo_dark.webp";
          } else {
            this.signInLogo = "assets/logos/toonballoon_logo_light.webp";
          }
        }
      })
    );

    // has to be in a set timeout or the loader throws an error if its the first thing thats called.
    setTimeout(() => {
      this.loaderService.loaderSubject$.next(false);
    }, 100);
  }

  //Clean up subscriptions on exit
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description takes the username and password and authenticates the user
   */
  signIn(): void {
    this.signInSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('Set up user password sign in');
    let email = this.signInForm.controls['email'].value;
    let password = this.signInForm.controls['password'].value;

    this.authService.signIn(email, password).then(result => {
      if (result.isValid()) {
        this.router.navigate([`/browse`], { relativeTo: this.route });
      }
    }).catch(reason => {
      this.signInSaving = false;
      this.loaderService.loaderSubject$.next(false);
      this.snackBar.openFromComponent(ErrorMessageComponent, {
        data: 'Could not sign in! Please check your username and password.',
        duration: 10000,
        panelClass: ['error-snackbar']
      });
    });

  }

}
